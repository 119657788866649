import * as React from 'react';
import { graphql} from 'gatsby';
import { Box, Text, Flex } from '@chakra-ui/react';
import SeoComponent from '../components/SeoComponent';
import WanaPressContentCard from '../components/WanaPressContentCard';
import Select from 'react-select';

/* SECTIONS */
import Hero from '../sections/Hero';

function Press(props) {
  const { data } = props;
  const heroSection = data.sanityPage.sections.find(item => item?._type === 'hero');
  const seoImg = data.sanityPage.seoImage?.image || heroSection?.heroImg?.bgImage;
  const allPost = [...data.readPosts.nodes];
 
  let years = [] as any[];
  allPost.map(item => years.push(Number(item.publishedAt.slice(0,4))));

  const dataArr = new Set(years);
  let result = [...dataArr];
  const yearsToShow = result.sort((a, b) => b - a);
  const objYears = yearsToShow.map((item, indx) => {
    return { value: item , label: item, index:indx}
  })
  
  const [contentTypeSelected, setContentTypeSelected] = React.useState(objYears[0]?.value);
  const filterByYear = allPost.filter(item=> Number(item.publishedAt.slice(0,4)) === contentTypeSelected);
  
  /* LOAD MORE ITEMS CONTROL */
  const itemsStep = data.allSanityPressPerPage?.nodes[0].totalPosts;
  const [allItemsLoaded, setAllItemsLoaded] = React.useState(itemsStep);

  const loadMore = () => {
    let allItemsLoadedCopy = allItemsLoaded;
    allItemsLoadedCopy += itemsStep;
    setAllItemsLoaded(allItemsLoadedCopy);
  };
  
  const [postsToRender, setPostsToRender] = React.useState<any[] | null>(null);
   React.useEffect(() => {
      setPostsToRender([...filterByYear])
      setAllItemsLoaded(itemsStep)
    }, [contentTypeSelected]);

  const getPostsLimit = () => {
    return allItemsLoaded
  };

  /* GET TAGS DATA */
  const tagsData = {};
  data.allSanityPressCategory.nodes.map((item, indx) => {
    tagsData[item.tag] = item;
  });

  return (
    <>
      <SeoComponent
        title={data.sanityPage.seoTitle}
        description={data.sanityPage.seoDescription}
        imageUrl={seoImg?.asset?.url}
      />
      
      <Box>
        {/* HERO */}
        <Hero
          header={heroSection.mainHeader.headerText}
          subheader={heroSection.accentHeader.headerText}
          heroBgImg={heroSection?.heroImg}
          heroCta={heroSection.heroCta}
        />

        {/* TABS BAR */}
        <Flex
          maxW={{base:'100%', msx:'87%', md:'87%', lg:'90%', lgs:'79%', xl:'1113px'}}
          h="max-content"
          mx="auto"
          mt={{base:'0px', md:'0px'}}
          mb={{base:'41px', md:'41px'}}
          justifyContent={{base:'center', lgs:'flex-end'}}
          alignItems={{base:'center', lg:'flex-start'}}
          position="relative"
          direction={{base:'column', lg:'row'}}
        >
          {/* POSTS TYPE SELECTOR */}
          <Flex
            maxW="278px"
            h="40px"
            alignItems="center"
            mt={{base:'15px', mds:'0'}}
          >
            <Text
              color="primaryDarker"
              fontSize={{base:'12px', md:'12px', lg:'13px', xl:'14px'}}
              fontWeight="900"
              lineHeight={{base:'16px', md:'16px', lg:'17px', xl:'18px'}}
              letterSpacing={{base:'0.5px', md:'0.5px'}}
              textTransform="uppercase"
              mr={{base:'20px', md:'22px'}}
            >
              {'Filter by year'}
            </Text>
            <Box
              border="2px"
              borderColor="#E3E1ED"
            >
              <Select
                className="state-dropdown-container"
                classNamePrefix="state-dropdown"
                isSearchable={false}
                defaultValue={objYears[0]}
                options={objYears}
                onChange={(contentType2) => setContentTypeSelected(contentType2.value)}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  outline: 'none',
                  outlineWidth: 'transparent',
                  outlineStyle: 'none',
                  colors: {
                    ...theme.colors,
                    neutral0: '#2F2865',
                    neutral90: 'white',
                    neutral70: 'white',
                    neutral80: 'white',
                    neutral50: 'white',
                  },
                })}
              />
            </Box>
          </Flex>
        </Flex>

        {/* POSTS LIST */}
        {postsToRender && postsToRender.length > 0 && (
          <Flex
            w={{base:'100%', msx:'100%', md:'100%', mds:'100%', lg:'79%', lgs:'90%', xl:'1230px'}}
            mx="auto"
            mt={{base:'5.14vw', xl:'74px'}}
            direction={{base:'column', md:'column', lg:'row'}}
            justifyContent={{base:'center', lg:'flex-start'}}
            flexWrap={{base:'wrap'}}
            alignItems={{base:'center'}}
          >
            {postsToRender.slice(0, getPostsLimit()).map((post, indx) => {
              return (
                <WanaPressContentCard 
                  key={indx}
                  item={post} 
                  route={'/press-releases/'}
                  allTags={tagsData}
                />
              );
            })}
          </Flex>
        )}

        {/* LOAD MORE */}
        {postsToRender && postsToRender.length >= itemsStep && getPostsLimit() < postsToRender.length && (
          <Flex
            w="148px"
            maxH="48px"
            color="white"
            bgColor="#2F2865"
            mx="auto"
            justifyContent="center"
            alignItems="center"
            mt={{base:'40px', ms:'50px'}}
            role="button"
            onClick={() => loadMore()}
            _hover={{bg:'#01A7CF'}}
          >
            <Text
              w="100%"
              fontSize="13px"
              fontWeight="900"
              lineHeight="1"
              letterSpacing="0.5px"
              textAlign="center"
              textTransform="uppercase"
              py="17.5px"
            >
              {'LOAD MORE'}
            </Text>
          </Flex>
        )}
        
        {/* NO POSTS */}
        {postsToRender && postsToRender.length === 0 && (
          <Box 
            w={{base:'90%', msx:'87%', md:'87%', lg:'84%', xl:'1190px'}}
            mx="auto"
            mt={{base:'5.14vw', xl:'74px'}}
            borderTop={{base:'1px'}}
            borderColor='primary'
          >
            <Text
              color='primary'
              fontSize="16px"
              textAlign={{base:'center', md:'left'}}
            >
              {'There are no press releases posts.'}
            </Text>
          </Box>
        )}
      </Box>
    </>
  );
}

export const query = graphql`
  query {
    sanityPage(slug: { current: { eq: "press-releases" } }) {
      _id
      slug {
        current
      }
      seoTitle
      seoDescription
      seoImage {
        image {
          asset {
            url
          }
        }
      }
      sections {
        ... on SanityHero {
          _key
          _type
          accentHeader {
            headerText
          }
          mainHeader {
            headerText
          }
          heroImg {
            bgImage {
              asset {
                url
                gatsbyImageData(placeholder: NONE, width: 6000)
              }
            }
            brightness
            contrast
          }
          heroCta {
            _rawTextContent
            textColor {
              color
            }
            bgColor {
              color
            }
            externalLink
            internallLink {
              route
            }
          }
        }
      }
    }
    allSanityPressPerPage {
      nodes {
        totalPosts
      }
    }
    allSanityPressCategory {
      nodes {
        tag
        tagColor {
          color
        }
      }
    }
    readPosts: allSanityPressPost(
      filter: { isPublic: { eq: true } }
      sort: {fields: publishedAt, order: DESC}
      ) {
      nodes {
        isPublic
        title
        iframe
        slug {
          current
        }
        mainImage {
          asset {
            gatsbyImageData(width: 570, height: 405, fit: MAX, placeholder: NONE)
          }
        }
        publishedAt
        resume
      }
    }
  }
`;

export default Press;